import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { Breadcrumb, Divider, Typography } from 'antd';
import './privacy-policy.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTES } from '../constants/routes';

const { Title, Text, Paragraph } = Typography;

const Chapter = () => {
  return (
    <Layout>
      <div className="page-wrapper">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href={ROUTES.HOME}>Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={ROUTES.TERMS_AND_CONDITIONS}>
            Regulamin
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
        <Title>Regulamin</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus et
          laboriosam voluptates iusto facere debitis nihil, ut totam odio nobis
          necessitatibus, quaerat tenetur consequatur repellat distinctio amet
          eaque tempora temporibus! Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Nemo vel, dignissimos expedita ratione repudiandae
          veritatis vero iusto ipsam magni molestiae quis adipisci id, libero
          doloremque cumque accusantium repellendus aut dicta, natus mollitia? A
          expedita, maxime esse ea cupiditate fugit pariatur, perferendis
          dolorum tempore, numquam velit explicabo? Est quas voluptates eaque?
        </Paragraph>
      </div>
    </Layout>
  );
};
export default Chapter;
